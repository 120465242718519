<template>
	<div
		ref="search"
		:class="[
			isExpanded || expanded ? 'w-screen h-screen max-h-screen md:w-auto md:max-w-96 shadow-lg bg-white dark:bg-mx-green-700' : 'rounded-bl-lg shadow-none bg-transparent',
			'transition-all duration-100 ease'
		]"
	>
		<div class="flex flex-col justify-start h-full">
			<!-- Search Input -->
			<div
				:class="[
					'relative h-[6vh] p-4',
					{ 'w-5/6 md:w-full ml-auto': isExpanded }
				]"
			>
				<!-- TODO i18n -->
				<input
					v-model="searchParams.search"
					type="text"
					placeholder="Search..."
					:class="[
						(isExpanded || expanded) ? 'bg-mx-gray-50' : 'bg-mx-gray-200 hidden',
						'w-full px-4 text-sm font-normal leading-tight rounded-lg shadow-inner outline-none h-10 text-mx-gray-500 placeholder-mx-gray-400 focus:ring-transparent dark:bg-mx-green-800 dark:border-mx-green-900 dark:text-mx-gray-300 dark:placeholder-mx-gray-300 focus:outline-none focus:ring-2 focus:ring-mx-orange'
					]"
					@focus="isExpanded = true"
					@keypress.enter="executeSearch"
				>
				<div
					v-if="!searchParams.search"
					class="absolute inset-y-0 top-0 right-0 flex items-center pr-3 lg:-right-1"
					:class="[
						(isExpanded || expanded) ? 'hidden' : 'flex'
					]"
				>
					<button
						class="text-xs font-bold uppercase cursor-pointer text-mx-gray-400 dark:text-mx-gray-400 hover:text-mx-gray-500 dark:hover:text-mx-gray-300"
						@click="isExpanded = true"
					>
						<MagnifyingGlassIcon
							class="w-5 h-5 text-mx-gray-400 dark:text-mx-gray-300"
							aria-hidden="true"
						/>
					</button>
				</div>
				<div
					v-else
					class="absolute inset-y-0 flex items-center pr-3 right-4"
				>
					<button
						class="text-xs font-bold uppercase cursor-pointer text-mx-gray-400 dark:text-mx-gray-400 hover:text-mx-gray-500 dark:hover:text-mx-gray-300"
						@click="clearSearch"
					>
						<XCircleIcon class="w-5 h-5" />
					</button>
				</div>
			</div>
			<TransitionGroup
				enter-active-class="transition duration-100 ease-in"
				enter-from-class="opacity-0"
				enter-to-class="opacity-100"
				leave-active-class="transition duration-100 ease-out"
				leave-from-class="opacity-100"
				leave-to-class="opacity-0"
			>
				<div
					v-if="isExpanded || expanded"
					class="h-full px-4 pb-4 overflow-y-auto border-t-2 border-mx-gray-200 dark:border-mx-green-800 scrollbar"
				>
					<!-- Advanced Search Fields -->
					<div class="flex flex-col pt-4 space-y-4">
						<!-- Search On -->
						<div class="flex flex-wrap items-center">
							<label
								for="searchOn"
								class="font-semibold text-black dark:text-mx-gray-300 mb-0.5 w-36"
							>
								<!-- TODO i18n -->
								Search On:
							</label>
							<select
								v-model="searchParams.searchOn"
								class="w-full h-10 px-4 py-2 text-sm font-normal leading-tight rounded-lg shadow-inner outline-none text-mx-gray-500 placeholder-mx-gray-400 focus:ring-transparent bg-mx-gray-50 dark:bg-mx-green-800 dark:border-mx-green-900 dark:text-mx-gray-300 dark:placeholder-mx-gray-300 focus:outline-none focus:ring-2 focus:ring-mx-orange"
							>
								<option value="">
									<!-- TODO i18n -->
									All
								</option>
								<!-- TODO i18n -->
								<option
									v-for="option in searchOnOptions"
									:key="option.value"
									:value="option.value"
								>
									{{ option.label }}
								</option>
							</select>
						</div>

						<!-- Filters -->
						<div
							:class="[
								'flex',
								searchParams.filters?.length ? 'flex-col' : 'items-center'
							]"
						>
							<label
								for="filters"
								class="font-semibold text-black dark:text-mx-gray-300"
							>
								<!-- TODO i18n -->
								Filters:
							</label>
							<div v-if="searchParams.filters?.length" class="mt-0.5">
								<div
									v-for="(_filter, index) in searchParams.filters"
									:key="index"
									class="flex items-center gap-2 mt-2 first-of-type:mt-0"
								>
									<select
										v-model="searchParams.filters[index]"
										class="w-full h-10 px-4 py-2 text-sm font-normal leading-tight rounded-lg shadow-inner outline-none text-mx-gray-500 placeholder-mx-gray-400 focus:ring-transparent bg-mx-gray-50 dark:bg-mx-green-800 dark:border-mx-green-900 dark:text-mx-gray-300 dark:placeholder-mx-gray-300 focus:outline-none focus:ring-2 focus:ring-mx-orange"
									>
										<!-- TODO i18n -->
										<option
											v-for="option in sortedFilterOptions"
											:key="option.value"
											:value="option.value"
										>
											{{ option.label }}
										</option>
									</select>

									<button
										class="pl-2 text-xs font-bold text-black uppercase cursor-pointer dark:text-mx-gray-400 hover:text-mx-gray-500 dark:hover:text-mx-gray-300"
										@click="removeFilterByIndex(index)"
									>
										<XCircleIcon class="w-5 h-5" />
									</button>
								</div>
							</div>

							<div
								:class="[
									searchParams.filters?.length ? 'flex items-center mt-2' : 'pl-2',
									'flex items-center'
								]"
							>
								<button
									class="text-lg font-semibold text-white rounded shadow-sm bg-mx-orange dark:bg-mx-orange/80 dark:hover:bg-mx-orange hover:bg-mx-orange/80 focus:outline-none focus:ring-2 focus:ring-mx-orange"
									@click="addFilter"
								>
									<PlusIcon class="w-5 h-5" />
								</button>
							</div>
						</div>

						<!-- Zone IDs -->
						<div>
							<label
								for="zoneIds"
								class="font-semibold text-black dark:text-mx-gray-300 mb-0.5"
							>
								<span>
									<!-- TODO i18n -->
									Zones:
								</span>
							</label>
							<Combobox
								v-model="searchParams.zoneIds"
								multiple
							>
								<div class="flex flex-col gap-2 flex-nowrap">
									<div class="flex w-full gap-4">
										<ComboboxButton
											v-slot="{ open }"
											class="h-10 w-full bg-mx-gray-100 text-center shadow-sm whitespace-nowrap inline-flex gap-x-1.5 items-center justify-center dark:bg-mx-green-800 hover:bg-mx-gray-200 dark:hover:bg-mx-green-600 px-4 rounded-lg text-sm font-semibold text-mx-gray-500 dark:text-mx-gray-300 outline-none"
										>
											{{ searchParams.zoneIds.length ? `${ searchParams.zoneIds.length } selected` : 'All' }}
											<ChevronDownIcon
												class="w-5 h-5 -mr-1 transition-all duration-100 ease-in-out text-mx-gray-400"
												:class="[ open ? 'transform rotate-180' : '' ]"
												aria-hidden="true"
											/>
										</ComboboxButton>
										<div class="flex justify-center">
											<button
												class="h-6 px-2 my-auto text-xs text-white rounded bg-mx-orange hover:bg-mx-orange/80 dark:bg-mx-orange/80 dark:hover:bg-mx-orange"
												@click="removeAllParamsByKey('zoneIds')"
											>
												<!-- TODO i18n -->
												Reset
											</button>
										</div>
									</div>
									<!-- TODO i18n -->
									<ComboboxOptions class="w-full overflow-auto rounded-md shadow-inner min-w-64 bg-mx-gray-100 dark:bg-mx-green-900/70 max-h-[400px]">
										<ComboboxOption
											v-for="option in zoneOptions"
											:key="option.value"
											v-slot="{ active }"
											:value="option.value"
										>
											<div
												class="relative py-2 pl-4 pr-10 cursor-pointer select-none whitespace-nowrap"
												:class="[
													{ 'bg-mx-gray-200 dark:bg-mx-green-600 dark:text-white': active },
													{ 'text-black dark:text-mx-gray-300': !active }
												]"
											>
												<span class="pr-3">
													{{ option.label }}
												</span>
												<span
													v-if="searchParams.zoneIds.includes(option.value)"
													class="absolute inset-y-0 right-0 flex items-center pr-3"
												>
													✓
												</span>
											</div>
										</ComboboxOption>
									</ComboboxOptions>
								</div>
							</Combobox>

							<div
								v-if="searchParams.zoneIds.length"
								class="w-full"
							>
								<div class="flex flex-wrap w-full gap-2 mt-2">
									<span
										v-for="(zoneId, index) in searchParams.zoneIds"
										:key="index"
										class="relative flex items-center justify-center px-2 py-1 text-center rounded-lg min-w-8 bg-mx-gray-100 dark:bg-mx-green-600"
									>
										{{ getZoneNameFromId(zoneId) }}

										<button
											class="absolute text-xs font-bold text-black uppercase cursor-pointer -top-1 -right-2 dark:text-mx-gray-400 hover:text-mx-gray-500 dark:hover:text-mx-gray-300"
											@click="removeParamByKeyAndIndex('zoneIds', index)"
										>
											<XCircleIcon class="w-5 h-5" />
										</button>
									</span>
								</div>
							</div>
						</div>

						<!-- Status IDs -->
						<div>
							<label
								for="accountStatusIds"
								class="font-semibold text-black dark:text-mx-gray-300 mb-0.5"
							>
								<!-- TODO i18n -->
								Account Status:
							</label>
							<Combobox
								v-model="searchParams.statusIds"
								multiple
							>
								<div class="flex flex-col gap-2 flex-nowrap">
									<div class="flex w-full gap-4">
										<ComboboxButton
											v-slot="{ open }"
											class="h-10 w-full bg-mx-gray-100 text-center shadow-sm whitespace-nowrap inline-flex gap-x-1.5 items-center justify-center dark:bg-mx-green-800 hover:bg-mx-gray-200 dark:hover:bg-mx-green-600 px-4 rounded-lg text-sm font-semibold text-mx-gray-500 dark:text-mx-gray-300 outline-none"
										>
											{{ searchParams.statusIds?.length ? `${searchParams.statusIds.length} selected` : 'All' }}
											<ChevronDownIcon
												class="w-5 h-5 -mr-1 transition-all duration-100 ease-in-out text-mx-gray-400"
												:class="[ open ? 'transform rotate-180' : '' ]"
												aria-hidden="true"
											/>
										</ComboboxButton>
										<div class="flex justify-center">
											<button
												class="h-6 px-2 my-auto text-xs text-white rounded bg-mx-orange hover:bg-mx-orange/80 dark:bg-mx-orange/80 dark:hover:bg-mx-orange"
												@click="removeAllParamsByKey('statusIds')"
											>
												<!-- TODO i18n -->
												Reset
											</button>
										</div>
									</div>
									<!-- TODO i18n -->
									<ComboboxOptions class="w-full min-w-64 overflow-auto bg-mx-gray-100 dark:bg-mx-green-900/70 rounded-md shadow-inner max-h-[500px]">
										<ComboboxOption
											v-for="option in sortedAccountStatusOptions"
											:key="option.value"
											v-slot="{ active }"
											:value="option.value"
										>
											<div
												class="relative py-2 pl-4 pr-10 cursor-pointer select-none whitespace-nowrap"
												:class="[
													{ 'bg-mx-gray-200 dark:bg-mx-green-600 dark:text-white': active },
													{ 'text-black dark:text-mx-gray-300': !active }
												]"
											>
												<span class="pr-3">
													{{ option.label }}
												</span>
												<span
													v-if="searchParams.statusIds?.includes(option.value)"
													class="absolute inset-y-0 right-0 flex items-center pr-3"
												>
													✓
												</span>
											</div>
										</ComboboxOption>
									</ComboboxOptions>
								</div>
							</Combobox>
							<div
								v-if="searchParams.statusIds?.length"
								class="flex flex-wrap gap-2 mt-2"
							>
								<span
									v-for="(statusId, index) in searchParams.statusIds"
									:key="index"
									class="relative flex items-center justify-center px-2 py-1 text-center rounded-lg min-w-8 bg-mx-gray-100 dark:bg-mx-green-600"
								>
									{{ getStatusNameFromId(statusId) }}
									<button
										class="absolute text-xs font-bold text-black uppercase cursor-pointer -top-1 -right-2 dark:text-mx-gray-400 hover:text-mx-gray-500 dark:hover:text-mx-gray-300"
										@click="removeParamByKeyAndIndex('statusIds', index)"
									>
										<XCircleIcon class="w-5 h-5" />
									</button>
								</span>
							</div>
						</div>

						<!-- Date Range Inputs -->
						<div
							:class="[
								'flex w-full',
								searchParams.dates?.length ? 'flex-col' : 'items-center'
							]"
						>
							<label
								v-tooltip="'Leave date(s) blank to include all dates before or after'"
								for="dates"
								class="font-semibold text-black dark:text-mx-gray-300 mb-0.5 pr-2 flex items-center"
							>
								<!-- TODO i18n -->
								Date Ranges: <InformationCircleIcon class="w-5 h-5 ml-2 text-mx-gray-400 dark:text-mx-gray-300" />
							</label>

							<div
								v-if="searchParams.dates?.length"
								class="flex flex-col gap-4 mb-2"
							>
								<div
									v-for="(_dateRange, index) in searchParams.dates"
									:key="index"
									class="flex flex-col items-center gap-2"
								>
									<div class="flex items-center w-full gap-2">
										<select
											v-model="searchParams.dates[index].name"
											class="w-full h-10 px-4 py-2 text-sm font-normal leading-tight rounded-lg shadow-inner outline-none text-mx-gray-500 placeholder-mx-gray-400 focus:ring-transparent bg-mx-gray-50 dark:bg-mx-green-800 dark:border-mx-green-900 dark:text-mx-gray-300 dark:placeholder-mx-gray-300 focus:outline-none focus:ring-2 focus:ring-mx-orange"
										>
											<!-- TODO i18n -->
											<option
												v-for="option in dateRangeOptions"
												:key="option.value"
												:value="option.value"
											>
												{{ option.label }}
											</option>
										</select>
										<button
											class="text-xs font-bold text-black uppercase cursor-pointer dark:text-mx-gray-400 hover:text-mx-gray-500 dark:hover:text-mx-gray-300"
											@click="removeParamByKeyAndIndex('dates', index)"
										>
											<XCircleIcon class="w-5 h-5" />
										</button>
									</div>

									<div class="flex items-center w-full gap-2">
										<input
											v-model="searchParams.dates[index].from"
											type="date"
											class="w-1/2 h-10 px-4 py-2 text-sm font-normal leading-tight rounded-lg shadow-inner outline-none text-mx-gray-500 placeholder-mx-gray-400 focus:ring-transparent bg-mx-gray-50 dark:bg-mx-green-800 dark:border-mx-green-900 dark:text-mx-gray-300 dark:placeholder-mx-gray-300 focus:outline-none focus:ring-2 focus:ring-mx-orange"
										>
										<input
											v-model="searchParams.dates[index].to"
											type="date"
											class="w-1/2 h-10 px-4 py-2 text-sm font-normal leading-tight rounded-lg shadow-inner outline-none text-mx-gray-500 placeholder-mx-gray-400 focus:ring-transparent bg-mx-gray-50 dark:bg-mx-green-800 dark:border-mx-green-900 dark:text-mx-gray-300 dark:placeholder-mx-gray-300 focus:outline-none focus:ring-2 focus:ring-mx-orange"
										>
									</div>
								</div>
							</div>
							<div class="flex items-center">
								<button
									class="text-lg font-semibold text-white rounded shadow-sm bg-mx-orange dark:bg-mx-orange/80 dark:hover:bg-mx-orange hover:bg-mx-orange/80 focus:outline-none focus:ring-2 focus:ring-mx-orange"
									@click="addDateRange"
								>
									<PlusIcon class="w-5 h-5" />
								</button>
							</div>
						</div>

						<!-- Assigned Email ID
						// TODO: Add back when BE supports this
						<div class="flex flex-col w-full">
							<label
								for="assignedEmailId"
								class="w-32 font-semibold text-black dark:text-mx-gray-300 mb-0.5"
							>
								Assignee:
							</label>

							<Menu
								v-slot="{ open }"
								as="div"
								class="relative inline-block text-left group"
							>
								<div>
									<MenuButton class="inline-flex w-full justify-center items-center gap-x-1.5 rounded-md bg-mx-gray-100 dark:bg-mx-green-800 px-4 h-10 whitespace-nowrap text-sm font-semibold text-mx-gray-500 dark:text-mx-gray-300 shadow-sm hover:bg-mx-gray-200 dark:hover:bg-mx-green-600">
										{{ searchParams.assignedEmailId ? getAssigneeNameFromId(searchParams.assignedEmailId) : 'All' }}
										<ChevronDownIcon
											class="w-5 h-5 -mr-1 transition-all duration-100 ease-in-out text-mx-gray-400"
											:class="[ open ? 'transform rotate-180' : '' ]"
											aria-hidden="true"
										/>
									</MenuButton>
								</div>

								<Transition
									enter-active-class="transition duration-100 ease-in"
									enter-from-class="opacity-0"
									enter-to-class="opacity-100"
									leave-active-class="transition duration-100 ease-out"
									leave-from-class="opacity-100"
									leave-to-class="opacity-0"
								>
									<MenuItems class="max-h-[400px] mt-2 overflow-y-auto rounded-md shadow-inner bg-mx-gray-100 dark:bg-mx-green-900 focus:outline-none">
										<div class="first-of-type:rounded-t-md last-of-type:rounded-b-md">
											<MenuItem
												v-for="(option, index) in csrOptions"
												:key="option.id"
												as="div"
												class="first-of-type:rounded-t-md last-of-type:rounded-b-md"
												@click="searchParams.assignedEmailId = option.value"
											>
												<template #default="{ active }">
													<button
														:class="[
															active ? 'bg-mx-gray-200 dark:bg-mx-green-600 dark:text-white' : 'text-gray-700 dark:text-mx-gray-300',
															'block w-full px-4 py-2 text-left focus:outline-transparent whitespace-nowrap',
															{ 'rounded-t-md' : index === 0 },
															{ 'rounded-b-md' : index === csrOptions.length - 1 }
														]"
													>
														{{ option.label }}
													</button>
												</template>
											</MenuItem>
										</div>
									</MenuItems>
								</Transition>
							</Menu>
						</div>
						-->

						<!-- Pagination Size & Page (For Mobile when not using table)
						<div class="flex items-center space-x-2">
							<label
								for="pageSize"
								class="w-24 font-medium"
							>
								Page Size:
							</label>
							<input
								v-model="searchParams.pageSize"
								type="number"
								min="1"
								placeholder="Page Size"
								class="w-full h-10 px-4 py-2 text-sm font-normal leading-tight rounded-lg shadow-inner outline-none text-mx-gray-500 placeholder-mx-gray-400 focus:ring-transparent bg-mx-gray-50 dark:bg-mx-green-800 dark:border-mx-green-900 dark:text-mx-gray-300 dark:placeholder-mx-gray-300 focus:outline-none focus:ring-2 focus:ring-mx-orange"
							>
						</div>

						<div class="flex items-center space-x-2">
							<label
								for="pageCurrent"
								class="font-medium w-36"
							>
								Page Current:
							</label>
							<input
								v-model="searchParams.pageCurrent"
								type="number"
								min="1"
								placeholder="Page Number"
								class="w-full h-10 px-4 py-2 text-sm font-normal leading-tight rounded-lg shadow-inner outline-none text-mx-gray-500 placeholder-mx-gray-400 focus:ring-transparent bg-mx-gray-50 dark:bg-mx-green-800 dark:border-mx-green-900 dark:text-mx-gray-300 dark:placeholder-mx-gray-300 focus:outline-none focus:ring-2 focus:ring-mx-orange"
							>
						</div>
            -->

						<!-- Sort By & Direction (For Mobile when not using table)
						<div class="flex items-center space-x-2">
							<label
								for="sort"
								class="w-24 font-medium"
							>
								Sort By:
							</label>
							<input
								v-model="searchParams.sort"
								type="text"
								placeholder="Sort By"
								class="w-full h-10 px-4 py-2 text-sm font-normal leading-tight rounded-lg shadow-inner outline-none text-mx-gray-500 placeholder-mx-gray-400 focus:ring-transparent bg-mx-gray-50 dark:bg-mx-green-800 dark:border-mx-green-900 dark:text-mx-gray-300 dark:placeholder-mx-gray-300 focus:outline-none focus:ring-2 focus:ring-mx-orange"
							>
						</div>

						<div class="flex items-center space-x-2">
							<label
								for="sortDirection"
								class="font-medium w-36"
							>
								Sort Direction:
							</label>
							<select
								v-model="searchParams.sortDirection"
								class="w-full h-10 px-4 py-2 text-sm font-normal leading-tight rounded-lg shadow-inner outline-none text-mx-gray-500 placeholder-mx-gray-400 focus:ring-transparent bg-mx-gray-50 dark:bg-mx-green-800 dark:border-mx-green-900 dark:text-mx-gray-300 dark:placeholder-mx-gray-300 focus:outline-none focus:ring-2 focus:ring-mx-orange"
							>
								<option value="ASC">
									ASC
								</option>
								<option value="DESC">
									DESC
								</option>
							</select>
						</div>
            -->
					</div>
				</div>

				<!-- Search Button -->
				<div
					v-if="isExpanded || expanded"
					class="px-4 py-6 bg-white border-t-2 border-mx-gray-200 dark:border-mx-green-800 dark:bg-mx-green-700"
				>
					<button
						class="w-full h-10 mb-2 font-semibold text-white rounded-lg bg-mx-orange hover:bg-mx-orange/80 dark:bg-mx-orange/80 dark:hover:bg-mx-orange focus:outline-none focus:ring-2 focus:ring-mx-orange"
						@click="executeSearch"
					>
						<!-- TODO i18n -->
						Search
					</button>

					<!-- Clear Search Button -->
					<button
						class="w-full h-10 mb-2 font-semibold text-black bg-white border-2 rounded-lg dark:border-0 border-mx-gray-300 dark:hover:text-white dark:text-mx-gray-400 dark:bg-mx-green-900 hover:bg-mx-gray-100 dark:hover:bg-mx-green-900/80 focus:outline-none focus:ring-2 focus:ring-mx-orange"
						@click="clearAllSearchParams"
					>
						<!-- TODO i18n -->
						Clear
					</button>
				</div>
			</TransitionGroup>
		</div>
	</div>
</template>

<script setup lang="ts">
import type { SearchParams } from '@/composables/useGlobalSearch'
import { MagnifyingGlassIcon, XCircleIcon, PlusIcon, ChevronDownIcon, InformationCircleIcon } from '@heroicons/vue/20/solid'
import {
	Combobox,
	ComboboxButton,
	ComboboxOptions,
	ComboboxOption
	/*
	Menu,
	MenuButton,
	MenuItem,
	MenuItems
	*/
} from '@headlessui/vue'
import {
	LEAD,
	PROSPECT,
	OPPORTUNITY,
	PENDING_INSTALL,
	PENDING_ACCEPTANCE,
	ACTIVE,
	INACTIVE,
	IGNORE,
	FAILED_INSTALL
} from '@/constants/accounts/statuses'

import { usePermissions } from '@/composables/usePermissions'
import { useZones } from '@/composables/useZones'
const { getZones, zones } = useZones()

const { fetchPermissions /* accountServiceRepOptions */ } = usePermissions()

const props = defineProps({
	expanded: {
		type: Boolean,
		default: false
	}
})

const { expanded } = toRefs(props)

const emit = defineEmits([ 'search', 'expanded' ])

const search = ref(null)

const removeParamByKeyAndIndex = (key: keyof typeof searchParams, index: number) => {
	if (Array.isArray(searchParams[key])) {
		(searchParams[key] as unknown[]).splice(index, 1)
	}
}

const removeAllParamsByKey = (key: keyof typeof searchParams) => {
	if (Array.isArray(searchParams[key])) {
		(searchParams[key] as unknown[]) = []
	}
}

const isExpanded = ref(false)

watch(isExpanded, (value) => {
	emit('expanded', value)
})

watch(expanded, (value) => {
	isExpanded.value = value
})

const searchParamsInitialState: SearchParams = {
	search: '',
	searchOn: '',
	searchTag: '',
	filters: [] as string[],
	zoneIds: [] as number[],
	statusIds: [] as number[],
	assignedEmailId: null,
	dates: [],
	pageSize: 100,
	pageCurrent: 1,
	sort: 'accountId',
	sortDirection: 'ASC'
}

const searchParams = reactive({
	...searchParamsInitialState
})

const accountStatusOptions = [
	{ value: 1, label: LEAD },
	{ value: 2, label: PROSPECT },
	{ value: 3, label: OPPORTUNITY },
	{ value: 4, label: PENDING_INSTALL },
	{ value: 5, label: FAILED_INSTALL },
	{ value: 6, label: PENDING_ACCEPTANCE },
	{ value: 7, label: ACTIVE },
	{ value: 8, label: INACTIVE },
	{ value: 10, label: IGNORE }
]

const sortedAccountStatusOptions = accountStatusOptions.sort((a, b) => a.label.localeCompare(b.label))

/*
const csrOptions = computed(() => {
	const options = accountServiceRepOptions.value.map((csr) => {
		return { label: csr.name, value: parseInt(csr.id), id: parseInt(csr.id) }
	})

	// Sort the options alphabetically by the label
	const sortedOptions = options.sort((a, b) => a.label.localeCompare(b.label))

	// Ensure the 'All' option stays at the top
	return [ { label: 'All', value: null, id: 0 }, ...sortedOptions ]
})
*/

const zoneOptions = computed(() => {
	const uniqueZones = new Set<{ label: string; value: number }>()
	zones.value?.forEach((zone) => {
		uniqueZones.add({ label: zone.name, value: parseInt(zone.id) })
	})

	// Convert the Set back to an array and sort by the label
	return Array.from(uniqueZones).sort((a, b) => a.label.localeCompare(b.label))
})

const getZoneNameFromId = (id: number) => {
	return zones.value?.find(zone => zone.id === id.toString())?.name || ''
}

const getStatusNameFromId = (id: number) => {
	return accountStatusOptions.find(status => status.value === id)?.label || ''
}

/*
const getAssigneeNameFromId = (id: number | null) => {
	return csrOptions.value.find(assignee => assignee.value === id)?.label || null
}
*/

/* TODO i18n */
const searchOnOptions = [
	{ label: 'Account ID', value: 'accountId' },
	{ label: 'Account Status', value: 'status' },
	{ label: 'Account Status ID', value: 'accountStatusId' },
	{ label: 'Activated', value: 'activated' },
	{ label: 'Address 1', value: 'address1' },
	{ label: 'Address 2', value: 'address2' },
	{ label: 'Address ID', value: 'addressId' },
	{ label: 'Assigned To', value: 'assignedTo' },
	{ label: 'Cable Pulled', value: 'cablePulled' },
	{ label: 'Cable Spliced', value: 'cableSpliced' },
	{ label: 'Cable Terminated', value: 'cableTerminated' },
	{ label: 'City', value: 'city' },
	{ label: 'City ID', value: 'cityId' },
	{ label: 'Conduit Problem', value: 'conduitProblem' },
	{ label: 'Contact ID', value: 'contactId' },
	{ label: 'Country', value: 'country' },
	{ label: 'Country ID', value: 'countryId' },
	{ label: 'County Property ID', value: 'countyPropertyId' },
	{ label: 'Email', value: 'email' },
	{ label: 'Email ID', value: 'emailId' },
	{ label: 'Excavation Method', value: 'excavationMethod' },
	{ label: 'Geo Location ID', value: 'geoLocationId' },
	{ label: 'Has Assigned To', value: 'hasAssignedTo' },
	{ label: 'Has Conduit Problem', value: 'hasConduitProblem' },
	{ label: 'Inside Installed', value: 'insideInstalled' },
	{ label: 'Inside Scheduled Date', value: 'insideScheduledDate' },
	{ label: 'Inside Scheduled Time', value: 'insideScheduledTime' },
	{ label: 'Latitude', value: 'latitude' },
	{ label: 'Longitude', value: 'longitude' },
	{ label: 'Name', value: 'name' },
	{ label: 'Neighborhood', value: 'neighborhood' },
	{ label: 'Note', value: 'noteBody' },
	{ label: 'Note ID', value: 'noteId' },
	{ label: 'Outside Installed', value: 'outsideInstalled' },
	{ label: 'Outside Scheduled Date', value: 'outsideScheduledDate' },
	{ label: 'Outside Scheduled Time', value: 'outsideScheduledTime' },
	{ label: 'Phone', value: 'phone' },
	{ label: 'Phone ID', value: 'phoneId' },
	{ label: 'Postal Code', value: 'postalCode' },
	{ label: 'Priority Install', value: 'priorityInstall' },
	{ label: 'Project', value: 'project' },
	{ label: 'Service Location ID', value: 'serviceLocationId' },
	{ label: 'Service Location Type ID', value: 'serviceLocationTypeId' },
	{ label: 'Source', value: 'source' },
	{ label: 'Speed', value: 'speed' },
	{ label: 'State', value: 'state' },
	{ label: 'State ID', value: 'stateId' },
	{ label: 'Survey Scheduled Date', value: 'surveyScheduledDate' },
	{ label: 'Survey Scheduled Time', value: 'surveyScheduledTime' },
	{ label: 'Surveyed', value: 'surveyed' },
	{ label: 'Temporary Line', value: 'temporaryLine' },
	{ label: 'Zone ID', value: 'zoneId' }
]

/* TODO i18n */
const dateRangeOptions = [
	{ label: 'Inside Scheduled Date', value: 'insideScheduledDateTs' },
	{ label: 'Outside Scheduled Date', value: 'outsideScheduledDateTs' },
	{ label: 'Survey Scheduled Date', value: 'surveyScheduledDateTs' }
]

const addDateRange = () => {
	if (!searchParams.dates) {
		searchParams.dates = []
	}
	searchParams.dates.push({
		name: '',
		from: 0,
		to: '+inf'
	})
}

watch(searchParams, () => {
	if (searchParams.dates?.length) {
		// loop through each date range and convert empty dates to the correct format for the API
		for (const dateRange of searchParams.dates) {
			convertEmptyDatesForApi(dateRange)
		}
	}
}, { deep: true })

const convertEmptyDatesForApi = (dateRange: { from: number | '+inf' | '-inf' | ''; to: number | '+inf' | '-inf' | '' }) => {
	if (!dateRange.from) {
		dateRange.from = 0
	}
	if (!dateRange.to) {
		dateRange.to = '+inf'
	}
}

/* TODO i18n */
const filterOptions = [
	{ label: 'Select Filter', value: '' },
	{ label: 'Inside Install To Do', value: 'insideInstallTodo' },
	{ label: 'Inside Install Done', value: 'insideInstallDone' },
	{ label: 'Outside Install To Do', value: 'outsideInstalledTodo' },
	{ label: 'Outside Install Done', value: 'outsideInstalledDone' },
	{ label: 'Site Survey To Do', value: 'siteSurveyToDo' },
	{ label: 'Site Survey Done', value: 'siteSurveyDone' },
	{ label: 'Cable Pull To Do', value: 'cablePullToDo' },
	{ label: 'Cable Pull Done', value: 'cablePullDone' },
	{ label: 'Cable Terminate To Do', value: 'cableTerminateToDo' },
	{ label: 'Cable Terminate Done', value: 'cableTerminateDone' },
	{ label: 'Cable Splice To Do', value: 'cableSpliceToDo' },
	{ label: 'Cable Splice Done', value: 'cableSpliceDone' },
	{ label: 'Priority Install No', value: 'priorityInstallNo' },
	{ label: 'Priority Install Yes', value: 'priorityInstallYes' },
	{ label: 'Has Move In No', value: 'hasMoveInNo' },
	{ label: 'Has Move In Yes', value: 'hasMoveInYes' },
	{ label: 'Trench', value: 'trench' },
	{ label: 'Conduit', value: 'conduit' },
	{ label: 'Troubleshoot', value: 'troubleshoot' },
	{ label: 'Activated', value: 'activated' },
	{ label: 'Not Activated', value: 'notActivated' },
	{ label: 'Unassigned', value: 'unassigned' }
]

const sortedFilterOptions = [
	filterOptions[0], // Keep 'Select Filter' at the top
	...filterOptions.slice(1).sort((a, b) => a.label.localeCompare(b.label))
]

const addFilter = () => {
	searchParams?.filters?.push('')
}

const removeFilterByIndex = (index: number) => {
	searchParams?.filters?.splice(index, 1)
}

const executeSearch = () => {
	// Emit the search parameters for the parent component to handle the search logic
	// but first remove any filters that are empty strings since the API doesn't support empty filter values
	searchParams.filters = searchParams.filters?.filter(filter => filter !== '')
	emit('search', { ...searchParams })
}

const clearSearch = () => {
	searchParams.search = ''
}

const clearAllSearchParams = () => {
	Object.assign(searchParams, searchParamsInitialState)
	executeSearch()
}

onMounted(() => {
	getZones()
	fetchPermissions('notes.create')
})
</script>

<style scoped>
/* WebKit Browsers */
/* width */
::-webkit-scrollbar {
	width: 8px;
	height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
	background: transparent;
	border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #6A6A6A;
	border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #ADADAD;
}

/* Remove scrollbar buttons */
::-webkit-scrollbar-button {
	display: none;
}

/* Firefox Browsers */
* {
	scrollbar-width: thin;
	scrollbar-color: #6A6A6A transparent;
}
</style>
