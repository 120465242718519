import { ref, computed } from 'vue'
import type { ComputedRef } from 'vue'
import { API_BASE, API_ENDPOINT_PORTAL_REDISEARCH_QUERY } from '@/constants'
import { useErrorReporter } from '@/composables/useErrorReporter'

type SearchResponse = {
	data: any[]
	count: number
	success: boolean
	page: number
}

type DateRange = {
	name: string
	from: number
	to: number | '+inf' | '-inf'
}

type SearchParams = {
	search: string
	searchOn?: string
	searchTag?: string
	filters?: any[]
	zoneIds?: any[]
	statusIds?: any[]
	assignedEmailId?: string | null
	dates?: DateRange[]
	pageSize?: number
	pageCurrent?: number
	sort?: string
	sortDirection?: 'ASC' | 'DESC'
}

const searchRef = ref()
const searchParams = ref<SearchParams>({ search: '' })
const searchResults = ref<any[]>([])
const mappedSearchResults = ref<any[]>([])
const searchResultCount = ref(0)
const searchResultPage = ref(1)
const searchResultSuccess = ref(false)
const isLoading = ref(false)
const isExpanded = ref(false)

const searchTerm = computed(() => searchParams.value.search || '') as Readonly<ComputedRef<string>>

/**
 * Composable providing search functionality using a Redisearch endpoint.
 * @returns {Object} A set of reactive properties and functions to handle global search.
 */
export function useGlobalSearch () {
	const { bugsnagReport } = useErrorReporter()

	/**
	 * Executes a search query against the Redisearch endpoint.
	 * @async
	 * @function
	 * @param {SearchParams} params - Parameters for the search query.
	 * @param {string} [endpoint] - The endpoint to query. Defaults to {@link API_ENDPOINT_PORTAL_REDISEARCH_QUERY}.
	 * @returns {Promise<void>} Resolves when the search is complete. Updates reactive properties with results.
	 * @throws Will report errors via Bugsnag if the query fails.
	 *
	 * @example
	 * const params: SearchParams = {
	 *   search: 'test',
	 *   pageSize: 20,
	 *   pageCurrent: 1,
	 *   sort: 'name',
	 *   sortDirection: 'ASC',
	 * }
	 * await handleSearch(params)
	 */
	const handleSearch = async (params: SearchParams, endpoint: string = API_ENDPOINT_PORTAL_REDISEARCH_QUERY) => {
		const endpointUrl = `${API_BASE}${endpoint}`

		searchParams.value = params

		// Clone the params to avoid mutation
		const payload = JSON.parse(JSON.stringify(params)) // Simple JSON-based cloning

		// Convert dates.to and dates.from to Unix timestamps
		if (payload?.dates) {
			payload.dates.forEach((dateRange: DateRange) => {
				if (typeof dateRange.from === 'string' && dateRange.from && !isNaN(Date.parse(dateRange.from))) {
					dateRange.from = Math.floor(Date.parse(dateRange.from) / 1000)
				}
				if (typeof dateRange.to === 'string' && dateRange.to && !isNaN(Date.parse(dateRange.to))) {
					dateRange.to = Math.floor(Date.parse(dateRange.to) / 1000)
				}
			})
		}

		isLoading.value = true

		await $fetch(endpointUrl, {
			method: 'POST',
			credentials: 'include',
			body: JSON.stringify(payload),
			onResponse ({ response }) {
				if (!response.ok) {
					searchResults.value = []
					searchResultCount.value = 0
					throw new Error(response._data?.error || response._data?.errors?.[0])
				}
				const responseData: SearchResponse = response._data
				searchResults.value = responseData.data
				searchResultCount.value = responseData.count
				searchResultSuccess.value = responseData.success
				searchResultPage.value = responseData.page
			}
		}).catch((e) => {
			bugsnagReport({
				error: e,
				showToast: true,
				toast: {
					title: 'Error',
					message: e.message || 'Failed to fetch search results'
				}
			})
		}).finally(() => {
			isLoading.value = false
		})
	}

	const handleExpanded = (expanded: boolean) => {
		isExpanded.value = expanded
	}

	return {
		searchRef,
		searchParams,
		searchResults,
		mappedSearchResults,
		searchResultCount,
		searchResultPage,
		searchResultSuccess,
		isLoading,
		isExpanded,
		searchTerm,
		handleSearch,
		handleExpanded
	}
}

export type { SearchParams, SearchResponse, DateRange }
